(function($) {
  var turnRelativeToAbsolute = function(urlToFix) {
    // If the first URL is a backslash, the URL is relative and Pinterest won't pull it. We need to make this a full URL.
    if (urlToFix.substr(0,1) === '/') {
        // Split the URL by backslashes
        var urlArray = location.href.split( '/' );
        // Find the web protocol i.e. http, https
        var protocol = urlArray[0];
        // We know there is always 2 backslashes before the website URL
        // Put the protocol and the base url together
        var websiteUrl = protocol + '//' + urlArray[2];              
        // Now add the base url to the relative image url to make it absolute
        urlToFix = websiteUrl + urlToFix;
    }
    return urlToFix;
  };

  var productRecordSocialAction = function(platform) {
    var generic = window.generic || {};
    if (Drupal.settings.loyalty_social_earning.includes(platform)) {
      generic.jsonrpc.fetch({
        'method': 'user.recordSocialAction',
        'params': [{
          action: 'share',
          url: window.location.href,
          platform: platform,
        }],
      });
    }
  };

  $(document).ready(function() {
  // Add media to the pinterest URL
    var $pinterestLink = $('.js-share--pinterest');
    $pinterestLink.each(function() {
      var $ogImage = $('meta[property="og:image"]');
      var mediaURL = false;
      if ($ogImage.length) {
        mediaURL = $ogImage.attr('content');
      } else {
        // Temp workaround
        // If there's no open graph tag for the time being, find the first image and use that
        mediaURL = $('img').first().attr('src');
      }
      mediaURL = turnRelativeToAbsolute(mediaURL);
      //Add description to the pinterest URL
      var $ogDescription = $('meta[property="og:description"]');
      var descriptionContent = false;
      if ($ogDescription.length) {
        descriptionContent = $ogDescription.attr('content');
      } else {
        descriptionContent = $(document).attr('title');
      }
      // Append this media to the openURL
      var url = $pinterestLink.attr('href') + '&media=';
      url += encodeURI(mediaURL) + '&description=';
      url += encodeURIComponent(descriptionContent);
      $pinterestLink.attr('href', url);
    });
  });

  $(document).on('click', '.js-share', function(event) {
    var width = 600;
    var height = 350;
    var openUrl = $(this).attr('href');
    // Allow for borders.
    var leftPosition = (window.screen.width / 2) - ((width / 2) + 10);
    // Allow for title and status bars.
    var topPosition = (window.screen.height / 2) - ((height / 2) + 50);
    var windowFeatures = "status=no,height=" + height + ",width=" + width;
    var $socialLink = $(this);
    var $platform = $socialLink.attr('aria-label') || '';
    windowFeatures += ",resizable=yes,left=" + leftPosition + ",top=" + topPosition;
    windowFeatures +=  ",screenX=" + leftPosition + ",screenY=" + topPosition;
    windowFeatures += ",toolbar=no,menubar=no,scrollbars=no,location=no,directories=no";

    // Specifically for the Quickshop on MPP, find and use the media URL for Pinterest sharing
    if($(this).hasClass('js-share--pinterest') && $(this).parents('.js-quickshop-container').length) {
      openUrl += '&media=' + turnRelativeToAbsolute($(this).parents('.js-quickshop-container').find('.js-gallery-img-large').attr('src'));
      openUrl += '&description=' + encodeURIComponent($(this).parents('.js-quickshop-container').find('.product_quickshop__header').text());
    }

    var productInfo = prodcat.data.getProduct($(this).parents().attr("data-product-id")),
        queryString = '';

    // Append product short description for douban social sharing
    if ($(this).hasClass('js-share--douban')) {
      queryString = (!productInfo) ? '' : $(productInfo.SHORT_DESC).text();
      openUrl += '&text=' + queryString;
    }

    // Append product sub line for kaixin001 social sharing
    if ($(this).hasClass('js-share--kaixin001')) {
      queryString = (!productInfo) ? '' : productInfo.SUB_LINE;
      openUrl += '&rtitle=' + queryString;
    }

    if (Array.isArray(Drupal.settings.loyalty_social_earning) && Drupal.settings.loyalty_social_earning.length > 0) {
      productRecordSocialAction($platform.toLowerCase().trim());
    }

    // Pinterest specific image in URL
    window.open(openUrl, 'sharer', windowFeatures);
    event.preventDefault();
  });

})(jQuery);
